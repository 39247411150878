<template>
  <!-- 直播容器 -->
  <div id="liveContainer">
    <iframe
      :src="`${link}`"
      frameborder="0"
      allowfullscreen="true"
    ></iframe>
  </div>
</template>
<script>
import {
  getLiveLink
} from "@/service/live";
export default {
  name: "livePage",
  data() {
    return {
      liveRoomNumber: this.$route.query.roomNumber,
      link:'',
    };
  },
  mounted() {
    this.getLink();
  },
  methods: {
    getLink(){
      getLiveLink(this.liveRoomNumber).then((res) => {
        if (res.success) {
          this.link = res.result;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
#liveContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>